angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('main', {
            views: {
                "content": {
                    templateUrl: 'web/main/main.tpl.html',
                    controller: 'mainCtrl',
                    controllerAs: 'controller'
                }
            }, resolve: {
                token: function ($q, $state, fingerinkSession, session) {
                    let deferred = $q.defer();
                    if (session.get()) {
                        fingerinkSession.renoveToken().then(deferred.resolve, () => deferred.reject('login'));//.catch(err => $q.reject("login"));
                    } else {
                        deferred.reject('login');
                    }
                    return deferred.promise;
                }
            }
        });
    })
    .controller('mainCtrl', function ($rootScope, $scope, $transitions, session, $mdSidenav, fingerinkSession, buyModal, $timeout, loginService, dashboardModal, swalService, websocketService) {
        var that = this;
        $scope.global = $rootScope;
        $scope.moment = (m) => moment(m);

        $scope.$on("$destroy", () => {
            deregisterTransitionStart();
            deregisterTransitionFalse();
        });

        $scope.$on("sessionChanged", checkEduardo);
        checkEduardo();
        $timeout(() => {
            $(window).trigger('resize');
        });

        that.resendConfirmEmail = () => swalService.requestSwal(
            '¿Reenviar correo?',
            '¿Quieres que te reenviemos el correo de confirmación de email?',
            'info',
            () => loginService.resendConfirmEmail()
        ).then(
            () => swalService.basicSwal("Correcto", "Hemos enviado un email a tu dirección de correo electrónico para poder confirmar tu cuenta.", "success"),
            () => swalService.basicSwal("Error", "Algo ha ido mal", "error")
        );

        //websocketService.connect(()=>websocketService.subscribe('/user/1', (d)=>console.log("ws",d)));

        var deregisterTransitionStart = $transitions.onStart({}, function (a) {
            $rootScope.uiRouterLoding = true;
            $mdSidenav('userNav').close();
            fingerinkSession.checkTokenAlive();
            if (a.to().name.indexOf("main") >= 0 && a.to().name.indexOf("buy") < 0) {
                if (!session.get().plan || session.get().plan.isExpired) {
                    return false;
                }
            }
            return true;
        });
        var deregisterTransitionFalse = $transitions.onFinish({}, (a) => { $rootScope.uiRouterLoding = false; return true; });

        function checkEduardo() {
            that.session = session.get();
            if (!that.session.user.company.nifCifIva) {
                dashboardModal.openModal();
            }
            if (!that.session.plan || moment(that.session.plan.expirationDate).isBefore(moment())) {
                buyModal.openModal(true);
            }
        }
    });
